<template>
  <div class="about_div">
    <div class="text_box">
      <h1>What is MAGdb?</h1>
      <p>
        The
        <em>Melatonin Associated Gene Exploration database (MAGEdb) </em> was
        designed to serve researchers work on melatonin with a user-friendly
        interface as well as performance-efficient frame. The database contains
        17,684 genes from 1926 species including animals, plants, and
        microorganisms. Among them: (1) We carefully examined 5,117 literatures
        and reviewed public databases such as NCBI, Uniprot, Ensembl, etc., and
        finally obtained 8,949 genes associated with melatonin; (2) 8735
        potential orthologues was identified in the Uniprot database
        accomplished using the reciprocal best hits strategy implemented; (3) We
        annotated the obtained genes, including gene and protein names,
        sequences, species, gene expression, protein structure, protein family
        and conserved domain, KEGG pathway, GO annotation, and protein-protein
        interaction; (4) The genes we collected were classified in detail,
        including by species, signaling pathways, gene function, etc.; (5) We
        have assembled a series of tools on the website to facilitate better
        analysis and research. The convenient web interface will make better use
        of melatonin-related genes. MAGEdb will be a useful resource for
        studying melatonin and help researchers further reveal the mechanism of
        action of melatonin at the molecular level.
      </p>
    </div>
    <div class="div_img">
      <img src="@/assets/imgs/about_us_pic.png" alt="" class="img_mag" />
    </div>

    <h1 >About MAGEdb</h1>
    <p>
      MAGEdb is constructed as a web application using Javascript and HTML for
      frontend development. The used core JavaScript libraries include Vue.js
      (https://vuejs.org/) for as the main frontend framework, vis.js
      (https://visjs.org) for network displaying, plotly.js
      (https://plotly.com/) for interactive Charts. High-level web framework
      Django (https://www.djangoproject.com/) is used for backend data
      preprocess and data analysis. The global search function is based on
      Elasticsearch module. Open source data management system Mysql is used for
      table-data saving and accessing.
    </p>
  </div>
</template>


<script>
export default {
  data() {
    return {
      images: [



      ],
      currentIndex: 0,

    };
  },
  computed: {

  },
  methods: {


  },

};
</script>



<style>
h1 {
  font-size: 24px;
  color: #1f2d3d;
  text-align: center;
  font-weight: bold;
}

p {
  font-weight: 500;
  text-indent: 2em;
  font-size: 19px;

  color: #333;
  line-height: 1.7;
}

em {
  font-style: italic;
  font-weight: bold;
  color: #0056b3;
}

.div_img {
  margin: 20px auto;
  width: 1450px;
}

.img_mag {
  width: 1450px;
}
</style>