<template>
  <div class="main_div">
    <img src="../assets/imgs/head.png" class="img_head" alt="" />

    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#424242"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true"
    >
      <el-menu-item index="home" class="title_first"> Home</el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <span class="title">Browse </span>
        </template>
        <el-menu-item index="animals" class="select_item">Animals</el-menu-item>
        <el-menu-item index="plants" class="select_item">Plants</el-menu-item>
        <el-menu-item index="microorganism" class="select_item"
          >Microorganism</el-menu-item
        >
      </el-submenu>
      <el-menu-item index="search" class="title">Search</el-menu-item>
      <el-submenu index="4" class="title">
        <template slot="title"> <span class="title">Tools </span></template>
        <el-menu-item index="sequence" class="select_item"
          >Sequence Convert
        </el-menu-item>
        <el-menu-item index="molecular" class="select_item"
          >Molecular Weight Caculation
        </el-menu-item>
        <el-menu-item index="orf" class="select_item"
          >ORF Prediction
        </el-menu-item>
        <el-menu-item index="cpg" class="select_item"
          >CpG Islands
        </el-menu-item>
        <el-menu-item index="pro" class="select_item"
          >Protein Property Analysis
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="help" class="title">Help</el-menu-item>
      <el-menu-item index="more" class="title">More</el-menu-item>
    </el-menu>

    <router-view> </router-view>

    <img src="@/assets/imgs/boot.png" class="img_head" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
    };
  },
};
</script>

<style  lang="less"  scoped>
.main_div {
  margin: 0 auto;
  width: 1580px;
}

.el-menu {
  // height: 100px;
  margin: 0;
  .title {
    font-size: 20px;
    font-weight: 600;
    margin: 5px 60px;
  }
  .title_first {
    font-size: 20px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 5px;
  }
}
.img_head {
  width: 100%;
  margin: 0;
}
.el-submenu {
  margin: 5px 20px;
}
.el-menu-item {
  margin: 5px 20px;
}
.select_item {
  font-size: 16px;
  font-weight: 600;
  width: 250px;
  // padding: 30 5px;
  margin: 10px 5px;
  color: antiquewhite;
}
.el-menu-demo {
  height: 70px;
}
</style>