import { render, staticRenderFns } from "./SearchListData.vue?vue&type=template&id=9ddb8b28&scoped=true"
import script from "./SearchListData.vue?vue&type=script&lang=js"
export * from "./SearchListData.vue?vue&type=script&lang=js"
import style0 from "./SearchListData.vue?vue&type=style&index=0&id=9ddb8b28&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ddb8b28",
  null
  
)

export default component.exports