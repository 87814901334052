<template>
  <div class="molecular">
    <el-card class="box-card">
      <div slot="header" class="header-title">
CpG Islands
</div>
      <div class="download-section"></div>
      <iframe src="tools_page/cpg_islands.html" width="1480px" height="490px">
      </iframe>
    </el-card>




  </div>
</template>


<script>
export default {
  name: "Molecular",
  data() {
    return {};
  },
};
</script>

<style scoped  lang="less">
.molecular {
  max-width: 1580px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.header-title {
  background-color: rgb(31, 78, 121);
  color: white;
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  text-transform: none !important;
}
</style>