<template>
  <div class="gene-info-container">
    <div class="first-header" v-if="geneDetail">Basic gene information</div>
    <table class="gene-table" v-if="geneDetail">
      <tbody>
        <tr>
          <td class="label">MAGEdb ID</td>
          <td>{{ geneDetail.database_id }}</td>
          <td class="label">NCBI gene ID</td>
          <td>{{ geneDetail.ncbi_gene_id }}</td>
          <td class="label">Source</td>
          <td>{{ geneDetail.source }}</td>
        </tr>
        <tr>
          <td class="label">Gene name</td>
          <td>{{ geneDetail.symbol }}</td>
          <td class="label">Gene type</td>
          <td colspan="3">{{ geneDetail.gene_type }}</td>
        </tr>
        <tr>
          <td class="label">Aliases</td>
          <td colspan="5">{{ geneDetail.aliases }}</td>
        </tr>
        <tr>
          <td class="label">Gene Location</td>
          <td colspan="5">{{ geneDetail.map_location }}</td>
        </tr>
        <tr>
          <td class="label">Organism</td>
          <td>{{ geneDetail.org_name }}</td>
          <td class="label">Organism ID</td>
          <td colspan="3">{{ geneDetail.tax_id }}</td>
        </tr>
        <tr>
          <td class="label">Taxonomic lineage</td>
          <td colspan="5">
            <div class="sequence-box">
              <p>{{ geneDetail.taxonomic_lineage }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <td class="label">Summary</td>
          <td colspan="5">
            <div class="sequence-box">
              <p>{{ geneDetail.gene_summary }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Basic protein information -->

    <div class="protein-info-container" v-if="proteinDetail">
      <div class="header">Basic protein information</div>
      <table class="protein-table">
        <colgroup>
          <col style="width: 15%" />
          <!-- Label (左侧)列宽 -->
          <col style="width: 20%" />
          <!-- 数据列宽度 -->
          <col style="width: 20%" />
          <!-- 高亮Label列宽 -->
          <col style="width: 30%" />
          <!-- 数据列宽度 -->
        </colgroup>
        <tbody>
          <tr>
            <td class="label">Protein name</td>
            <td>{{ proteinDetail.transcript_protein_name }}</td>
            <td class="label">Uniprot ID</td>
            <td>{{ proteinDetail.uniprot_id }}</td>
          </tr>
          <tr>
            <td class="label">PDB ID</td>
            <td>{{ proteinDetail.pdb || "" }}</td>
            <td class="label">PROSITE</td>
            <td>{{ proteinDetail.prosite || "" }}</td>
          </tr>
          <tr>
            <td class="label">InterPro</td>
            <td colspan="3">{{ proteinDetail.interpro || "" }}</td>
          </tr>
          <tr>
            <td class="label">Pfam ID</td>
            <td colspan="3">{{ proteinDetail.pfam_id || "" }}</td>
          </tr>
          <tr>
            <td class="label">PANTHER ID</td>
            <td>{{ proteinDetail.panther || "" }}</td>
            <td class="label">CDD ID</td>
            <td>{{ proteinDetail.cdd || "" }}</td>
          </tr>
          <tr>
            <td class="label">Protein function</td>
            <td colspan="3">
              <div class="sequence-box">
                <p>{{ proteinDetail.protein_function }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- sequence-container -->

    <div class="sequence-container" v-if="sequence[0]">
      <div class="header">Sequence</div>
      <table class="sequence-table">
        <tbody>
          <!-- 基因序列 -->
          <tr>
            <td class="label">Gene Sequence</td>
            <td>
              <div class="sequence-box">
                <p style="width: 1330px">{{ sequence[0].gene_seq }}</p>
              </div>
            </td>
          </tr>
          <!-- 蛋白序列 -->
          <tr>
            <td class="label">Protein Sequence</td>
            <td>
              <div class="sequence-box">
                <p  style="width: 1330px">{{ sequence[1].protein_seg_path }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Structure-->
    <div class="table-data" v-if="PDB_data.length > 0">
      <div class="header">Structure</div>
      <PDB></PDB>
    </div>

    <!-- orthologs-container -->

    <div class="orthologs-container" v-if="ortholog_length > 0">
      <div class="header">Orthologs</div>
      <el-table
        :data="orthology"
        border
        style="width: 100%"
        class="custom-table"
      >
        <el-table-column prop="database_id" label="MAGdb ID" width="220">
          <template #default="{ row }">
            <a @click="goToDetail(row.database_id)" href="javascript:void(0)"
              >{{ row.database_id }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="transcript_protein_name"
          label="Protein name"
          width="350"
        />
        <el-table-column
          prop="symbol"
          label="Gene name"
          width="350"
          class-name="font_size_24"
        />

        <el-table-column prop="uniprot_id" label="Uniprot ID" />

        <el-table-column prop="org_name" label="Organism" width="350" />
      </el-table>
    </div>

    <!-- Gene expression   table data    v-->

    <div class="table-data" v-if="gene_expression_data_length > 0">
      <div class="header">Gene expression</div>
      <!-- 添加 ref，确保 ECharts 容器正确初始化 -->
      <Gene_express_echartVue
        :table_data="all_data.gene_expression_data"
      ></Gene_express_echartVue>
    </div>

    <!-- Pathways -->
    <div class="table-data" v-if="pathway_img_length > 0">
      <div class="header">Pathways</div>
      <Pathway_img></Pathway_img>
    </div>
    <!-- network-picture -->
    <div class="table-data" v-if="string_img">
      <div class="header">Protein interaction network</div>
      <img :src="string_img" alt="" class="protein_network_img" />
      <div class="border_box">
        <span class="entry">Entry</span>
        <span>String: {{ string_img_name }}</span>
      </div>
    </div>

    <!-- External links-->
    <div class="experimental-information" v-if="external_links">
      <div class="header">External links</div>

      <table class="gene-table">
        <tbody>
          <tr>
            <td class="label">KEGG ID</td>
            <td colspan="5">{{ external_links.kegg_id }}</td>
          </tr>
          <tr>
            <td class="label">Ensembl ID</td>
            <td colspan="5">{{ external_links.ensembl_geneids }}</td>
          </tr>
          <tr>
            <td class="label">Pathway</td>
            <td colspan="5">
              <div class="sequence-box">
                {{ external_links.kegg_pathway }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">Cellular Component</td>

            <td colspan="5">
              <div class="sequence-box">
                {{ CC_data }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">Biological Process</td>

            <td colspan="5">
              <div class="sequence-box">
                {{ BP_data }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">Molecular Function</td>

            <td colspan="5">
              <div class="sequence-box">
                {{ MF_data }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Experimental information-->
    <div class="experimental-information" v-if="experimental_data">
      <div class="header">Experimental information</div>

      <table
        class="gene-table"
        
        v-for="data in experimental_data"
        :key="data.id"
      >
        <tbody>
          <tr>
            <td class="label" colspan="5">Experimental information</td>
            <td class="label" colspan="3">DOI:{{ data.doi }}</td>
          </tr>
          <tr>
            <td class="label">Background</td>
            <td colspan="5">{{ data.background }}</td>
          </tr>
          <tr>
            <td class="label">Problem</td>
            <td colspan="5">{{ data.problem }}</td>
          </tr>
          <tr v-if="data.object_and_group">
            <td class="label">Object and group</td>
            <td colspan="5">{{ data.object_and_group }}</td>
          </tr>
          <tr v-if="data.conclusion">
            <td class="label">Conclusion</td>
            <td colspan="5">{{ data.conclusion }}</td>
          </tr>
          <tr>
            <td class="label">Gene intervention</td>
            <td colspan="5">{{ data.gene_intervention }}</td>
          </tr>
          <tr v-if="data.result">
            <td class="label">Result</td>
            <td colspan="5">{{ data.result }}</td>
          </tr>
          <tr v-if="data.treatment">
            <td class="label">Treatment</td>
            <td colspan="5">{{ data.treatment }}</td>
          </tr>
          <tr v-if="data.treatment_site">
            <td class="label">Treatment site</td>
            <td colspan="5">{{ data.treatment_site }}</td>
          </tr>
          <tr v-if="data.treatment_result">
            <td class="label">Treatment result</td>
            <td colspan="5">{{ data.treatment_result }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import Gene_express_echartVue from "@/components/Gene_express_echart.vue";
import Pathway_img from "@/components/Pathway_img.vue";
import PDB from "@/components/PDB.vue";
export default {
  components: { Gene_express_echartVue, PDB, Pathway_img },
  data() {
    return {
      all_data: [],
      geneDetail: [],
      proteinDetail: [],
      magdb_id: "",
      sequence: [],
      orthology: [],
      gene_expression_data_length: 0,
      ortholog_length: 0,
      pathway_img_length: 0,
      string_img: null,
      string_img_name: null,
      experimental_data: [],
      PDB_data: null,
      external_links: {},

      CC_data: [],
      BP_data: [],
      MF_data: [],
    };
  },
  methods: {
    async get_detail_data() {
      var demo = this;

      console.log(demo.magdb_id);
      await this.$http
        .get("genedetail", {
          params: {
            magdb_id: demo.magdb_id,
          },
        })
        .then((res) => {
          demo.all_data = res.data.response;

          demo.geneDetail = demo.all_data.gene_detail;
          demo.proteinDetail = demo.all_data.protein_detail;
          demo.sequence = demo.all_data.sequence;
          demo.orthology = demo.all_data.orthology;
          demo.string_img = demo.all_data.string_img;
          demo.string_img_name = demo.all_data.protein_detail.string;
          demo.external_links = demo.all_data.external_links;

          demo.PDB_data = demo.all_data.alphafold_url;
          console.log("demo.alphaflod_url.length:");

          //传给子组件
          // sessionStorage.setItem("gene_expression_data", demo.all_data.gene_expression_data);

          console.log(demo.all_data);
          console.log(demo.external_links);
          demo.BP_data = demo.external_links.biological_process;
          demo.MF_data = demo.external_links.molecular_function;
          demo.CC_data = demo.external_links.cellular_component;
          this.ortholog_length = demo.orthology.length;
          this.gene_expression_data_length =
            Object.entries(demo.all_data.gene_expression_data).length - 1;
        });
    },
    async get_experimental_data() {
      var demo = this;

      await this.$http
        .get("experimental", {
          params: {
            magdb_id: demo.magdb_id,
          },
        })
        .then((res) => {
          console.log("experimental:", res);
          demo.experimental_data = res.data.data[0];
        });
    },
    goToDetail(id) {
      sessionStorage.setItem("magdb_id", id);
      this.magdb_id = id;
      this.get_detail_data();
      location.reload(); //刷新页面
    },

    async get_pathway_img_length() {
      console.log(this.img_quest);
      var demo = this;
      await this.$http
        .get("pathway", {
          params: {
            magdb_id: demo.magdb_id,
            pageSize: 12,
            page: 1,
          },
        })
        .then((res) => {
          demo.pathway_img_length = res.data.data.length;
        });
    },
  },
  created() {
    this.magdb_id = sessionStorage.getItem("magdb_id");
    this.get_detail_data();
    this.get_experimental_data();
    this.get_pathway_img_length();
  },
};
</script>

<style scoped lang="less">
td {
  vertical-align: top;
  text-align: left;
  word-wrap: break-word; /* 自动换行 */
  padding: 10px !important;
}

.gene-info-container {
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  width: 100%;

  margin: 20px auto;
}

.header {
  background-color: #2c539e;
  color: white;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin: 20px auto;
  margin-top: 50px;
}

.first-header {
  background-color: #2c539e;
  color: white;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin: 10px auto;
}

.gene-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  font-size: 22px;
}

.gene-table td {
  border: 1px solid #ddd;

  vertical-align: top;
}

.label {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 150px;
}

.gene-table td[colspan="5"] {
  text-align: left;
}

.gene-table td[colspan="3"] {
  text-align: left;
}

.gene-table td {
  text-align: left;
}

.gene-table .label {
  background-color: #f2f2f2;
  font-weight: bold;
  white-space: nowrap;
}

.gene-table td {
  border: 1px solid #ddd;
}

.gene-table .label {
  width: 150px;
}

/* .protein-info-container */
.protein-info-container {
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  width: 100%;
  margin-bottom: 20px;
}

.protein-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  font-size: 20px;
}

.protein-table td {
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: top;
  text-align: left;
  word-wrap: break-word; /* 自动换行 */
}

.label {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 150px;
}

.protein-table td[colspan="3"] {
  text-align: left;
}

.protein-table td {
  text-align: left;
}

.protein-table .label {
  background-color: #f2f2f2;
  font-weight: bold;
  white-space: nowrap;
}

.protein-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

/* sequence-container */

/* sequence-container */
.sequence-container {
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Table styles */
.sequence-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  font-size: 20px;
}

.sequence-table td {
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: top;
}

.label {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 150px;
  text-align: left;
  white-space: nowrap;
  padding-top: 20px;
}


/* Sequence box styles */
.sequence-box {
  height: 150px; /* Limit height */
  overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 2px;
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
}

/* Preformatted text styles */
pre {
  margin: 0;
  font-family: monospace; /* Use monospaced font */
  white-space: pre-wrap; /* Preserve line breaks, but allow wrapping */
  word-wrap: break-word; /* Break long words */
}

.orthologs-container {
  margin: 50px 0px;
}
a {
  cursor: pointer;
  color: rgb(31, 78, 121);
  text-decoration: none;
}

/* 修改表头样式 */
::v-deep .el-table__header-wrapper th {
  font-size: 17px; /* 表头字体大小 */
  background-color: rgb(242, 242, 242) !important; /* 表头背景颜色 */
  color: #333; /* 表头字体颜色 */
  font-weight: 600; /* 加粗字体 */
  text-align: center; /* 表头文字居中 */
  padding: 15px 8px; /* 内边距调整 */
}

/* 确保单元格内容居中 */
::v-deep .el-table__body-wrapper td {
  text-align: center; /* 单元格文字居中 */
  padding: 8px; /* 内边距调整 */
  color: black;
}
.species-list-div {
  text-align: center;
}

.border_box {
  height: 50px;
  width: 700px;
  border: 4px solid slategray;
  border-radius: 6px;
  margin-left: 10px;
  margin-bottom: 20px;

  /* Flexbox 用于整体居中对齐 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: start; /* 水平间隔，自动分布空间 */
  padding: 0 5px; /* 内边距避免文字贴边 */

  .entry {
    font-size: 28px;
    font-weight: 700;
    height: 100%; /* 占满父容器高度 */
    width: 150px;
    border-right: 4px solid slategray;

    /* Flexbox 实现水平和垂直居中 */
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
  }

  span {
    font-size: 28px;

    margin-left: 20px;
  }
}
.protein_network_img {
  transition: all 0.5s;
  height: 500px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.protein_network_img:hover {
  transform: scale(1.3); /* 让图片在悬停时放大 */
  // border: 2px solid;
}
</style>