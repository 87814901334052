import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/components/Main.vue'
import Home from '@/components/Home.vue'
import More from '@/components/more.vue'
import Help  from '@/components/Help.vue'
import Search from '@/components/Search.vue'
import Animals from '@/components/Animals.vue'
import Plants from '@/components/Plants.vue'
import Microorganism from '@/components/Microorganism.vue'
import Sequence from '@/components/Tools/Sequence.vue'
import Cpg from '@/components/Tools/Cpg.vue'
import Molecular from '@/components/Tools/Molecular.vue'
import Orf from '@/components/Tools/Orf.vue'
import Pro from '@/components/Tools/Pro.vue'



import DetailData from '@/components/DetailData.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/main', component: Main ,
    redirect: 'home',
    children: [
      { path: '/home', component: Home },
      { path: '/more', component: More },
      { path: '/help', component: Help},
      { path: '/search', component: Search },
      { path: '/animals', component: Animals },
      { path: '/plants', component: Plants },
      { path: '/microorganism', component: Microorganism},
      { path: '/sequence', component: Sequence},
      { path: '/molecular', component: Molecular},
      { path: '/orf', component: Orf},
      { path: '/cpg', component: Cpg},
      { path: '/pro', component: Pro},
 

      { path: '/detailData', component: DetailData },

      
    ]
  },


  { path: '/', redirect: '/main' },
  
]

const router = new VueRouter({
  // mode: 'history',
  routes
})



export default router
