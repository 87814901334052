<template>
  <div class="list-data">
    <div class="species-list-div">
      <el-table
        :data="list_data"
        border
        style="width: 100%"
        class="custom-table"
      >
        <el-table-column prop="database_id" label="MAGEdb ID" width="150">
          <template #default="{ row }">
            <a @click="goToDetail(row.database_id)" href="javascript:void(0)"
              >{{ row.database_id }}
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="symbol" label="Gene name" width="150" />
        <el-table-column prop="transcript_protein_name" label="Protein name" />
        <el-table-column prop="org_name" label="Organism" width="160" />
        <el-table-column prop="tax_id" label="Taxonomy ID" width="150" />
        <el-table-column prop="pathway" label="Pathway component" />
        <el-table-column prop="ncbi_gene_id" label="NCBI gene ID" width="160" />
        <el-table-column prop="uniport_id" label="Uniport ID" width="130" />
        <el-table-column prop="source" label="Source" width="120" />
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="list_data_quest.total"
        :page-size="7"
        :current-page.sync="list_data_quest.page"
        @current-change="handlePageChangeList"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      list_data_quest: {
        pageSize: 6,
        page: 1,
        total: 100,
      },
      list_id: "",
      list_data: [],
    };
  },
  watch: {
    searchParams: {
      handler(newParams) {
        this.get_list_data(newParams);
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handlePageChangeList(page) {
      this.list_data_quest.page = page; // 更新当前页数
      this.get_list_data(this.searchParams); // 重新加载数据
    },

    async get_list_data(params) {
      console.log({
        ...params,
        page: this.list_data_quest.page,
        pageSize: this.list_data_quest.pageSize,
      });
      var demo = this;
      await this.$http
        .get("search", {
          params: {
            ...params,
            page: this.list_data_quest.page,
            pageSize: this.list_data_quest.pageSize,
          },
        })
        .then((res) => {
          console.log(res);
          demo.list_data = res.data.data;
          demo.list_data_quest.total = res.data.total;
        });
    },
    goToDetail(id) {
      sessionStorage.setItem("magdb_id", id);
      this.$router.push("/detailData");
    },
  },
  created() {
    // console.log(222);
    console.log(sessionStorage.getItem("list_id"));

    this.list_data_quest.tax_id = sessionStorage.getItem("list_id");
    // this.get_list_data();
  },
};
</script>

<style scoped lang="less" scoped>
.animal-gallery {
  text-align: center;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px 5px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 5px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.pic_div {
  height: 210px;
  width: 210px;
  border: 2px solid;
  margin: 5px;
  img {
    height: 100%;
  }
}
.title {
  margin: 2px auto;
  height: 50px;
  width: 180px;
  border-bottom: 5px solid;
}
.el-pagination {
  margin: 50px 10px;
}
a {
  cursor: pointer;
  color: rgb(31, 78, 121);
  text-decoration: none;
}

/* 修改表头样式 */
::v-deep .el-table__header-wrapper th {
  font-size: 17px; /* 表头字体大小 */
  background-color: rgb(242, 242, 242) !important; /* 表头背景颜色 */
  color: #333; /* 表头字体颜色 */
  font-weight: 600; /* 加粗字体 */
  text-align: center; /* 表头文字居中 */
  padding: 15px 8px; /* 内边距调整 */
}

/* 确保单元格内容居中 */
::v-deep .el-table__body-wrapper td {
  text-align: center; /* 单元格文字居中 */
  padding: 8px; /* 内边距调整 */
  color: black;
}
.species-list-div {
  text-align: center;
}
</style>
